
import { computed, defineComponent, PropType, Ref, ref } from "vue";
import { Form, FormActions } from "vee-validate";

import { AddressModel, SubscriptionType } from "@/models";
import { AppActionTypes, useStore } from "@/store";
import { validateAddress } from "@/validation";

import EditAddress from "./EditAddress.vue";
import KendoModal from "./KendoModal.vue";
import {
    ModelState,
    hasErrors,
    getModelStateFromResponse
} from "@elite/validation";

export default defineComponent({
    name: "address-card",
    components: {
        "v-form": Form,
        "edit-address": EditAddress,
        "kendo-modal": KendoModal
    },
    props: {
        title: { type: String, required: true, default: "" },
        address: { type: Object as PropType<AddressModel>, required: true },
        canEdit: { type: Boolean, required: true, default: false },
        subscriptionId: { type: String, required: true, default: "" },
        subscriptionType: { type: String, required: true }
    },
    setup(props) {
        const { getters, dispatch } = useStore();

        const showModal = ref(false);

        const modelState: Ref<ModelState> = ref({
            modelErrors: [],
            propertyErrors: {}
        });

        const rules = getters.getModelValidationRules("addressModel");

        const onSubmit = async (
            values: unknown,
            actions: FormActions<Record<string, unknown>>
        ): Promise<void> => {
            if (!(await validateAddress(props.address))) {
                actions.setFieldError("line1", "Address is invalid");
                return;
            }

            const response = await dispatch(
                AppActionTypes.updateCustomerAddress,
                {
                    model: props.address,
                    type: props.subscriptionType as SubscriptionType,
                    subscriptionId: props.subscriptionId
                }
            );

            if (hasErrors(response)) {
                modelState.value = getModelStateFromResponse(response, actions);
            } else {
                showModal.value = false;
            }
        };

        return {
            rules,
            loading: computed(() => getters.loading),
            showModal,
            onSubmit,
            modelState
        };
    }
});

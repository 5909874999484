
import { computed, defineComponent } from "vue";
import KendoModal from "./KendoModal.vue";

export default defineComponent({
    name: "cvv-modal",
    components: { "kendo-modal": KendoModal },
    props: {
        modelValue: {
            type: Boolean,
            default: false
        }
    },
    emits: ["update:modelValue"],
    setup(props, context) {
        return {
            showModal: computed({
                get: () => props.modelValue,
                set: (value) => context.emit("update:modelValue", value)
            })
        };
    }
});

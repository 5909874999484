
import { defineComponent, PropType, ref } from "vue";

import { SubscriptionModel, SubscriptionType } from "@/models";
import { formatDate, formatCurrency } from "@/helpers";
import { AppActionTypes, useStore } from "@/store";

import UpdateBillingFrequency from "./UpdateBillingFrequency.vue";
// These imports are for future the removed components above which were removed per RM7581
// https://ett2.elitebusinesssolutions.com/tasks/62200
//import UpdateNextBillingDate from "./UpdateNextBillingDate.vue";
import CancelSubscriptionButton from "@/components/CancelSubscriptionButton.vue";
import CancelSubscriptionModal from "@/components/CancelSubscriptionModal.vue";
import EditButton from "./EditButton.vue";
import SubscriptionStatus from "./SubscriptionStatus.vue";
import { routePaths } from "@/router/routePaths";

export default defineComponent({
    name: "subscription-overview-card",
    components: {
        "update-billing-frequency": UpdateBillingFrequency,
        //"update-next-billing-date": UpdateNextBillingDate,
        "cancel-subscription-button": CancelSubscriptionButton,
        "cancel-subscription-modal": CancelSubscriptionModal,
        "subscription-status": SubscriptionStatus,
        "edit-button": EditButton
    },
    props: {
        subscription: {
            type: Object as PropType<SubscriptionModel>,
            required: true
        },
        reactivate: {
            type: Boolean,
            default: false,
            required: false
        }
    },
    setup(props) {
        const { dispatch } = useStore();

        const showConfirmReactive = ref(props.reactivate);
        let showModal = ref(false);

        const reactivateSubscription = async (): Promise<void> => {
            await dispatch(AppActionTypes.reactivateSubscription, {
                id: props.subscription.id ?? "",
                type: props.subscription.type as SubscriptionType
            });
        };

        const cancelSubscription = (subscription: SubscriptionModel): void => {
            showModal.value = true;
        };

        return {
            id: props.subscription.id as string,
            type: props.subscription.type as string,
            formatDate,
            formatCurrency,
            showConfirmReactive,
            reactivateSubscription,
            showCancellationModal: showModal,
            cancelSubscription: cancelSubscription,
            communityPath: routePaths.community
        };
    }
});


import {
    computed,
    defineComponent,
    PropType,
    reactive,
    Ref,
    ref,
    watch
} from "vue";
import { Form, FormActions } from "vee-validate";
import {
    ModelState,
    ValFormInput,
    ValSummary,
    hasErrors,
    getModelStateFromResponse
} from "@elite/validation";

import { PaymentModel, SubscriptionType } from "@/models";
import {
    formatDate,
    getPaymentTypeCode,
    mapSelectListItemOptions
} from "@/helpers";
import { AppActionTypes, useStore } from "@/store";

import KendoModal from "./KendoModal.vue";
import CvvModal from "./CvvModal.vue";
import EditButtonVue from "./EditButton.vue";

export default defineComponent({
    name: "payment-card",
    components: {
        "kendo-modal": KendoModal,
        "v-form": Form,
        "val-form-input": ValFormInput,
        "cvv-modal": CvvModal,
        "edit-button": EditButtonVue,
        "val-summary": ValSummary
    },
    props: {
        paymentInfo: {
            type: Object as PropType<PaymentModel>,
            required: true
        },
        subscriptionType: {
            type: String,
            required: true
        },
        subscriptionId: {
            type: String,
            required: true
        },
        customerNumber: {
            type: String,
            required: true
        }
    },
    setup(props) {
        const { getters, dispatch, state } = useStore();

        const model = reactive({
            ...props.paymentInfo,
            // make the user enter their number and code
            cardNumber: "",
            cvvCode: ""
        });
        const showModal = ref(false);
        const showCvvInfo = ref(false);

        const modelState: Ref<ModelState> = ref({
            modelErrors: [],
            propertyErrors: {}
        });

        watch(model, () => {
            if (model.cardNumber) {
                model.paymentTypeCode = getPaymentTypeCode(model.cardNumber);
            }
        });

        const onSubmit = async (
            values: PaymentModel,
            actions: FormActions<Record<string, unknown>>
        ): Promise<void> => {
            const response = await dispatch(
                AppActionTypes.updatePaymentMethod,
                {
                    model,
                    type: props.subscriptionType as SubscriptionType,
                    subscriptionId: props.subscriptionId,
                    customerNumber: props.customerNumber
                }
            );

            if (hasErrors(response)) {
                modelState.value = getModelStateFromResponse(response, actions);
            } else {
                showModal.value = false;
            }
        };

        return {
            model,
            showModal,
            showCvvInfo,
            formatDate,
            modelState,
            rules: computed(() =>
                getters.getModelValidationRules("paymentModel")
            ),
            onSubmit,
            monthOptions: computed(() =>
                mapSelectListItemOptions(state.settings?.monthItems)
            ),
            yearOptions: computed(() =>
                mapSelectListItemOptions(
                    state.settings?.paymentExpirationYearItems
                )
            )
        };
    }
});


import { computed, defineComponent, PropType, reactive } from "vue";
import {
    ValFormInput,
    ModelValidationRules,
    ValSummary,
    ModelState
} from "@elite/validation";
import { AddressModel } from "@/models";
import { useStore } from "@/store";
import { formatPhoneNumber } from "@/helpers";

const defaultAddressValue: AddressModel = {
    line1: "",
    line2: "",
    city: "",
    stateCode: "",
    countryCode: "",
    postalCode: ""
};

export default defineComponent({
    components: {
        "val-form-input": ValFormInput,
        "val-summary": ValSummary
    },
    props: {
        address: {
            type: Object as PropType<AddressModel>,
            required: true,
            default: (): AddressModel => ({ ...defaultAddressValue })
        },
        rules: {
            type: Object as PropType<ModelValidationRules>,
            required: true,
            default: (): ModelValidationRules => ({})
        },
        modelState: {
            type: Object as PropType<ModelState>,
            required: false,
            default: (): ModelState => ({ modelErrors: [], propertyErrors: {} })
        }
    },
    setup(props) {
        const { state } = useStore();

        const onPhoneChanged = (payload: Event): void => {
            model.phone = formatPhoneNumber(model.phone);
        };

        const model = reactive(props.address);
        return {
            stateOptions: computed(() => state.states),
            model,
            onPhoneChanged
        };
    }
});

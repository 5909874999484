import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 0,
  class: "row justify-content-center"
}
const _hoisted_3 = {
  key: 1,
  class: "row"
}
const _hoisted_4 = { class: "col-xl-5 offset-xl-1 col-lg-6 mb-2" }
const _hoisted_5 = { class: "col-xl-5 col-lg-6" }
const _hoisted_6 = {
  key: 0,
  class: "row mb-2"
}
const _hoisted_7 = { class: "col-12" }
const _hoisted_8 = {
  key: 1,
  class: "row mb-2"
}
const _hoisted_9 = { class: "col-12" }
const _hoisted_10 = {
  key: 2,
  class: "row"
}
const _hoisted_11 = { class: "col-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading_indicator = _resolveComponent("loading-indicator")!
  const _component_subscription_overview_card = _resolveComponent("subscription-overview-card")!
  const _component_address_card = _resolveComponent("address-card")!
  const _component_payment_card = _resolveComponent("payment-card")!

  return (!_ctx.hasError)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.loading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_loading_indicator, { class: "col" })
            ]))
          : _createCommentVNode("", true),
        (_ctx.subscription && _ctx.subscription.id)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_subscription_overview_card, {
                  subscription: _ctx.subscription,
                  reactivate: _ctx.reactivate
                }, null, 8, ["subscription", "reactivate"])
              ]),
              _createElementVNode("div", _hoisted_5, [
                (_ctx.subscription.shippingAddress)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                      _createElementVNode("div", _hoisted_7, [
                        _createVNode(_component_address_card, {
                          address: _ctx.subscription.shippingAddress,
                          title: "Shipping Address",
                          "can-edit": _ctx.subscription.canEditAddress || false,
                          "subscription-id": _ctx.subscriptionId,
                          "subscription-type": _ctx.type
                        }, null, 8, ["address", "can-edit", "subscription-id", "subscription-type"])
                      ])
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.subscription.billingAddress)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                      _createElementVNode("div", _hoisted_9, [
                        _createVNode(_component_address_card, {
                          address: _ctx.subscription.billingAddress,
                          title: "Billing Address",
                          "can-edit": _ctx.subscription.canEditAddress || false,
                          "subscription-id": _ctx.subscriptionId,
                          "subscription-type": _ctx.type
                        }, null, 8, ["address", "can-edit", "subscription-id", "subscription-type"])
                      ])
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.subscription.paymentInfo)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                      _createElementVNode("div", _hoisted_11, [
                        _createVNode(_component_payment_card, {
                          "payment-info": _ctx.subscription.paymentInfo,
                          "customer-number": _ctx.subscription.customerNumber ?? '',
                          "subscription-id": _ctx.subscription.id,
                          "subscription-type": _ctx.subscription.type ?? ''
                        }, null, 8, ["payment-info", "customer-number", "subscription-id", "subscription-type"])
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ])
            ]))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}
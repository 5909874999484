
import { Form } from "vee-validate";
import { defineComponent, computed, reactive, ref } from "vue";
import { ValFormInput } from "@elite/validation";
import { AppActionTypes, useStore } from "@/store";
import { UpdateBillingFrequencyModel } from "@/models";
import { mapSelectListItemOptions } from "@/helpers";

import KendoModal from "./KendoModal.vue";

export default defineComponent({
    name: "update-billing-frequency",
    components: {
        "kendo-modal": KendoModal,
        "v-form": Form,
        "val-form-input": ValFormInput
    },
    props: {
        subscriptionId: { type: String, required: true },
        weeks: {
            type: Number,
            required: true
        }
    },
    setup(props) {
        const { state, getters, dispatch } = useStore();

        const model: UpdateBillingFrequencyModel = reactive({
            weeks: props.weeks
        });

        const rules = computed(
            () =>
                getters.getModelValidationRules(
                    "updateBillingFrequencyModel"
                ) ?? {}
        );

        const showModal = ref(false);

        const onSubmit = async (): Promise<void> => {
            await dispatch(AppActionTypes.updateBillingFrequency, {
                id: props.subscriptionId,
                model
            });

            showModal.value = false;
        };

        return {
            showModal,
            model,
            rules,
            weeksOptions: computed(() =>
                mapSelectListItemOptions(state.settings?.billingFrequencyItems)
            ),
            onSubmit
        };
    }
});


import { computed, defineComponent } from "vue";

import { AppActionTypes, useStore } from "@/store";
import { loadData } from "@/helpers";
import { SubscriptionType } from "@/models";

import SubscriptionOverviewCard from "@/components/account/SubscriptionOverviewCard.vue";
import PaymentCard from "@/components/account/PaymentCard.vue";
import AddressCard from "@/components/account/AddressCard.vue";

export default defineComponent({
    components: {
        "subscription-overview-card": SubscriptionOverviewCard,
        "payment-card": PaymentCard,
        "address-card": AddressCard
    },
    props: {
        type: { type: String, required: true },
        subscriptionId: { type: String, required: true },
        reactivate: { type: Boolean, default: false }
    },
    setup(props) {
        const store = useStore();
        const { state, dispatch, getters } = store;

        loadData(store, () => {
            dispatch(AppActionTypes.getSubscriptionDetail, {
                type: props.type as SubscriptionType,
                id: props.subscriptionId
            });
        });

        return {
            loading: computed(() => getters.loading),
            subscription: computed(
                () =>
                    store.state.subscriptionDetails[props.subscriptionId] || {}
            ),
            hasError: computed(() => !!state.error)
        };
    }
});

import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = {
  key: 0,
  class: "form-group mb-3"
}
const _hoisted_3 = { class: "form-group mb-3" }
const _hoisted_4 = { class: "form-group mb-3" }
const _hoisted_5 = { class: "form-group mb-3" }
const _hoisted_6 = { class: "form-group mb-3" }
const _hoisted_7 = { class: "form-group mb-3" }
const _hoisted_8 = { class: "form-group mb-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_val_summary = _resolveComponent("val-summary")!
  const _component_val_form_input = _resolveComponent("val-form-input")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.modelState.modelErrors.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_val_summary, {
            errors: _ctx.modelState.modelErrors
          }, null, 8, ["errors"])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_val_form_input, {
        modelValue: _ctx.model.line1,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.line1) = $event)),
        type: "text",
        rules: _ctx.rules.line1
      }, null, 8, ["modelValue", "rules"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_val_form_input, {
        modelValue: _ctx.model.line2,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.line2) = $event)),
        type: "text",
        rules: _ctx.rules.line2
      }, null, 8, ["modelValue", "rules"])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_val_form_input, {
        modelValue: _ctx.model.city,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model.city) = $event)),
        type: "text",
        rules: _ctx.rules.city
      }, null, 8, ["modelValue", "rules"])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_val_form_input, {
        modelValue: _ctx.model.stateCode,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.model.stateCode) = $event)),
        type: "select",
        options: _ctx.stateOptions,
        rules: _ctx.rules.stateCode
      }, null, 8, ["modelValue", "options", "rules"])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createVNode(_component_val_form_input, {
        modelValue: _ctx.model.postalCode,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.model.postalCode) = $event)),
        type: "text",
        rules: _ctx.rules.postalCode
      }, null, 8, ["modelValue", "rules"])
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createVNode(_component_val_form_input, {
        modelValue: _ctx.model.phone,
        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.model.phone) = $event)),
        type: "text",
        rules: _ctx.rules.phone,
        onInput: _ctx.onPhoneChanged
      }, null, 8, ["modelValue", "rules", "onInput"])
    ])
  ]))
}